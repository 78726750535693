.smart_pacs_loading {
  display: inline-block;
  user-select: none;
}
.smart_pacs_loading:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff #fff #fff transparent;
  animation: smart_pacs_loading 1s linear infinite;
}
@keyframes smart_pacs_loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.customSelect__wrapper .customSelect__control {
  @apply border-green-19dbc4 w-full rounded border  text-sm leading-tight text-white bg-transparent cursor-pointer;
  min-height: 28px;
}
.customSelect__wrapper .customSelect__control:hover {
  @apply border-green-19dbc4;
}

.customSelect__wrapper .customSelect__control:focus,
.customSelect__wrapper .customSelect__control--menu-is-open {
  /* @apply border-gray-500 outline-none; */
  @apply border-green-19dbc4 outline-none !shadow-none;
}

.customSelect__wrapper .customSelect__control--is-focused {
  @apply border-green-19dbc4 outline-none !shadow-none;
}

.customSelect--is-disabled .customSelect__control--is-disabled {
  @apply pointer-events-none;
}

.css-1hwfws3 {
  padding: 0;
}

.customSelect__wrapper .customSelect__indicator-separator {
  @apply hidden;
}

.customSelect__wrapper .customSelect__dropdown-indicator {
  padding: 4px;
}

.customSelect__wrapper .customSelect__option {
  color: #d6d6d6;
  font-size: 14px;
  display: flex;
  align-items: center;
  @apply flex flex-row items-center cursor-pointer;
}

.customSelect__wrapper .customSelect__option--is-selected {
  @apply bg-transparent;
}

.customSelect__wrapper .customSelect__single-value {
  @apply text-aqua-pale pl-1;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customSelect__wrapper.customSelect--is-disabled {
  @apply pointer-events-auto cursor-not-allowed;
}

.customSelect__wrapper .customSelect__value-container--is-multi {
  @apply inline-block truncate px-3 py-2;
}

.ohif-select .customSelect__menu {
  background-color: transparent !important;
  border-radius: 6px;
}

.ohif-select .customSelect__menu-list {
  overflow-x: hidden !important;
  margin: 4px;
  padding-top: 0px !important;
}

.ohif-select .customSelect__menu-list::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
.ohif-select .customSelect__placeholder {
  @apply text-aqua-pale pl-1.5;
}

.ohif-select .customSelect__menu-list::-webkit-scrollbar-thumb {
  background-color: #19dbc4;
  border-radius: 10px;
}

.customSelect__option--is-focused {
  @apply !bg-green-182c2a;
}

.ohif-select .customSelect__option:hover {
  @apply !bg-green-182c2a;
}

.ohif-select .customSelect__menu {
  @apply border-2 border-green-19dbc4;
  border-radius: 6px;
  background-color: #151515 !important;
}

.css-1hwfws3 {
  all: unset !important;
}

/*
  覆蓋 input
*/
.smart_pacs_app .mantine-Input-input,
.smart_pacs_app .mantine-PasswordInput-innerInput {
  background-color: transparent;
  height: 44px;
  color: #fff;
  font-size: 16px;
  border: 1px solid #f9f9f9;
  border-radius: 8px;
}

.smart_pacs_app .mantine-Input-input:focus,
.smart_pacs_app .mantine-PasswordInput-input:focus,
.smart_pacs_app .mantine-PasswordInput-input:focus-within {
  border: 1px solid #19dbc4;
}

.smart_pacs_app .mantine-PasswordInput-innerInput {
  border: none;
}

.smart_pacs_app .mantine-PasswordInput-visibilityToggle:hover {
  background: none;
}

.smart_pacs_app .center_input .mantine-Input-input,
.smart_pacs_app .center_input .mantine-PasswordInput-innerInput {
  text-align: center;
}

/*
  設定頁 input 樣式覆蓋
*/
.user_self_form .mantine-Input-input,
.user_self_form .mantine-PasswordInput-innerInput {
  background: #182C2A;
}

.user_self_form .mantine-Input-input:read-only,
.user_self_form .mantine-PasswordInput-innerInput:read-only {
  border: none;
  cursor: auto;
}

/*
  study list input 樣式覆蓋
*/
.study_list .mantine-Input-input,
.study_list .mantine-PasswordInput-innerInput {
  border: 1px solid #19dbc4;
  height: 32px;
  border-radius: 4px;
}

/*
  上傳 dicom table
*/
.study_list .upload_dicom_table .mantine-Input-input,
.study_list .upload_dicom_table .mantine-PasswordInput-innerInput {
  height: 44px;
}

/*
  study search input 樣式覆蓋
*/
.search_study .mantine-Input-input,
.search_study .mantine-PasswordInput-innerInput {
  border: 1px solid #3E5D59;
  height: 36px;
  border-radius: 4px;
  padding-left: 38px;
  padding-right: 38px;
}
.search_study .mantine-InputWrapper-root {
  @apply w-full max-w-[380px] max-sm:max-w-[200px];
}


/*
  覆蓋 Progress
*/
.smart_pacs_app .mantine-Progress-root {
  @apply bg-green-182c2a;
}
.smart_pacs_app .mantine-Progress-bar:first-of-type,
.smart_pacs_app .mantine-Progress-bar:last-of-type {
  @apply rounded-[4px];
}
/* warning */
.smart_pacs_app .warning .mantine-Progress-bar:first-of-type,
.smart_pacs_app .warning .mantine-Progress-bar:last-of-type {
  @apply bg-orange-ea6a3d;
}
/* alert */
.smart_pacs_app .alert .mantine-Progress-bar:first-of-type,
.smart_pacs_app .alert .mantine-Progress-bar:last-of-type {
  @apply bg-red-ed1c24;
}


/*
  覆蓋 Radio
*/
.smart_pacs_app .mantine-Radio-labelWrapper {
  @apply cursor-pointer text-[18px] text-white;
}

.smart_pacs_app .mantine-Radio-radio:checked {
  background: none;
  @apply cursor-pointer;
}

.smart_pacs_app .mantine-Radio-label {
  @apply cursor-pointer;
}

.smart_pacs_app .mantine-Radio-radio {
  outline: none;
  @apply bg-transparent border border-green-19dbc4 cursor-pointer;
}

.smart_pacs_app .mantine-Radio-inner svg {
  @apply left-[50%] top-[50%] !transform translate-x-[-50%] translate-y-[-50%];
  @apply text-green-19dbc4 w-[10px] h-[10px];
}

.smart_pacs_app .day_option .mantine-Radio-label {
  @apply text-[14px] pl-[4px];
}
